@import "../../DesignSystem/DesignSystem.style.less";

:global {
    .pmCalendar {
        border: 1px solid #f0f0f0;

        .ant-select {
            width: auto;
            height: 24px;
        }

        .ant-picker-cell-selected {
            color: @pfx-color-white;
            border: 0;
        }

        .ant-picker-calendar-date-value {
            color: currentColor;
        }

        .ant-picker-cell-disabled.ant-picker-cell-today
            .ant-picker-cell-inner::before {
            border-color: transparent;
        }
    }

    .pmCalendar--hiddenYear {
        .ant-picker-calendar-header {
            .ant-radio-button-wrapper {
                border-radius: 4px;
            }
            .ant-radio-button-wrapper:last-child {
                display: none;
            }
        }
    }
}
