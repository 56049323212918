@import "../../components/DesignSystem/DesignSystem.style.less";

.page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: #fafafa;
}

.form {
    width: 240px;
}

.errorAlert {
    position: fixed;
    top: 32px;
    z-index: 10;
}
