:global {
    .dropzoneFullWidth {
        > .ant-upload {
            width: 100%;
        }
    }
    .uploadPreviewFullSize {
        .ant-upload-list-item-actions a {
            display: none;
        }
        .ant-upload-list-picture-card-container {
            width: 100% !important;
        }
        .ant-upload.ant-upload-select-picture-card {
            width: 100% !important;
        }
    }
}
