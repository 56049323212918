@import "../DesignSystem/DesignSystem.style.less";

:global {
    .IconUpload {
        max-width: 100px !important;
        .ant-upload-list-item-actions a {
            display: none !important;
        }
        .ant-upload-list-picture-card-container {
            width: 100% !important;
        }
        .ant-upload.ant-upload-select-picture-card {
            width: 100% !important;
        }
        .ant-upload-list-item-actions a {
            display: none;
        }
    }
    // .templateEditForm {
    //     .ucForms .ant-form-item.ucFormsField--spacing-default {
    //         margin: 0;
    //     }
    // }
}
