@import "./DesignSystem.style.less";

:global {
    #root {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    body {
        background: @pfx-color-white;
        color: @text-color;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        overflow: hidden;
    }

    a {
        color: @pfx-color-primary;
        text-decoration: none !important;
        &:hover {
            color: @pfx-color-hover;
        }
    }

    b,
    strong {
        font-weight: @font-weight-bold !important;
    }

    // fix for malformed spinner
    .ucSpin > .ant-spin-nested-loading > div > .ant-spin > .anticon {
        font-size: 32px !important;
    }
    .ucTableCellContent .ucStatus {
        margin-top: -4px;
        .anticon {
            font-size: 16px !important;
        }
    }

    // because antd styles import order is different in PM and in unity, antd overrides unity style for sorting tooltip arrow in PM
    .ucTable
        .ucTableHeaderColumnContextMenu__topRow
        .ucTableHeaderColumnContextMenu__button {
        position: absolute !important;
    }

    .visible-overflow {
        .ucTable .ant-table-thead .ucTable__column .ucTable__cellContent {
            overflow: visible;
        }
    }

    // temporary bug in Unity @see https://pricefx.atlassian.net/browse/PFUN-27360
    .ucCheckbox__label {
        white-space: normal !important;
    }
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
    color: @heading-color;
}

// @DEPRECATED, don't use, don't extend
h1 {
    font-size: @heading-1-size;
    line-height: @line-height-1;
}

h2 {
    font-size: @heading-2-size;
    line-height: @line-height-2;
}

h3 {
    font-size: @heading-3-size;
    line-height: @line-height-3 !important;
    margin-bottom: @pfx-spacing-16;
}

h4 {
    font-size: @heading-4-size;
    line-height: @line-height-4;
    margin-bottom: @pfx-spacing-8;
}

h5 {
    font-size: @heading-5-size;
    line-height: @line-height-5;
}
