@import "../../components/DesignSystem/DesignSystem.style.less";

:global {
    .pmLogin,
    .pmFirstPassword,
    .pmNewPassword,
    .pmSecondAuth {
        max-width: 350px;

        .ucForms .ucFormsField--width-min,
        .ucForms .ucFormsFieldGroup--width-min {
            width: 260px;
        }

        .ant-card {
            width: 100%;
        }
    }
}

.errorAlert {
    position: fixed;
    top: 32px;
    z-index: 10;
}

.centeredContent {
    display: flex;
    place-items: center;
    flex-direction: column;
}

.logo {
    width: 124px;
}

.card {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}

.footerText {
    color: #a6a6a6;
}

.buttonRow {
    margin-top: @pfx-spacing-8;
    text-align: right;
}
