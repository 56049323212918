@import "../DesignSystem.style.less";

:global {
    .pmSelect {
        float: none;
        text-align: left;
        max-width: 100% !important;

        .ant-select-multiple {
            .ant-select-selector {
                .ant-select-selection-search-input {
                    padding: 0;
                }
            }
        }
    }
    .pmSelect__addItem {
        display: flex;
        padding: 8px;
        flex-flow: column nowrap;

        .ant-form-item {
            margin-bottom: 8px;

            .ant-form-item-extra {
                min-height: 0;
            }
        }
    }

    .pmSelect--withoutBorder {
        .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
        .ant-select-single.ant-select-show-arrow
            .ant-select-selection-placeholder {
            padding-right: 30px;
        }

        .ant-select-selection-item {
            font-size: 12px;
            font-weight: @font-weight-bold;
        }

        .ant-select-selector {
            border-color: transparent !important;
            box-shadow: none !important;
        }

        .ant-select-single:not(.ant-select-customize-input)
            .ant-select-selector {
            border: none;
            font-weight: 500;
            box-shadow: none;
            padding: 0;
            background: transparent;

            .ant-select-selection-search {
                left: -1px;
            }

            .ant-select-selector__rendered {
                margin-left: 0;
            }

            &--single,
            &:focus,
            &:active {
                box-shadow: none;
            }

            .ant-select-selection-placeholder {
                color: @pfx-color-grey-7;
                opacity: 1;
            }
        }

        .ant-select-selector-selected-value {
            color: @text-color;
        }
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background: @pfx-color-blue-1;
        color: @pfx-color-grey-8;
        font-weight: @font-weight-medium;
    }
}
