@import "../../DesignSystem/DesignSystem.style.less";

:global {
    .tag-small {
        font-size: 10px;
        line-height: normal;
    }

    .ucTable__cell > .ant-tag {
        margin-top: -3px;
    }
}
