:global {
    .pmDraggerWithIcon {
        .ant-upload.ant-upload-drag {
            background: #fafafa;
            .ant-upload-drag-container {
                padding: 32px 35px 26px;
            }
        }
    }
}
