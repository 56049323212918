@import "../../DesignSystem/DesignSystem.style.less";

:global {
    .pmButtonGroup {
        margin-top: @pfx-spacing-24;
        margin-bottom: @pfx-spacing-24;
        &--spacing-none {
            margin-top: 0;
            margin-bottom: 0;
        }
        & > * {
            margin-left: @pfx-spacing-8;
        }
        &--buttonsSpacing-small > * {
            margin-left: @pfx-spacing-8 !important;
        }

        &--buttonsSpacing-medium > * {
            margin-left: @pfx-spacing-16 !important;
        }

        &--buttonsSpacing-large > * {
            margin-left: @pfx-spacing-24 !important;
        }

        & > :first-child {
            margin-left: 0 !important;
        }
        .ant-btn-link {
            line-height: 34px;
        }
        .ant-btn-link.ant-btn-sm {
            line-height: 24px;
        }
        .ant-btn-link.ant-btn-lg {
            line-height: 41px;
        }
    }
}
