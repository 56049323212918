@import "../../../DesignSystem.style.less";

.validation {
    margin-right: 5px;
    font-size: 12px;
}

.successColor {
    color: @pfx-color-green-3;
}

.greyColor {
    color: @pfx-color-grey-7;
}

.errorColor {
    color: @pfx-color-red;
}
