@import "../DesignSystem.style.less";

:global {
    .pmCascader {
        .ant-input {
            font-size: 13px;
        }
    }

    .ant-input-group-wrapper .pmCascader {
        width: 100% !important;
    }

    .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
    .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
        background: @pfx-color-blue-1;
        color: @pfx-color-grey-8;
        font-weight: @font-weight-medium;
    }
}
