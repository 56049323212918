@import "../../DesignSystem/DesignSystem.style.less";

:global {
    .pmTable {
        .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
            margin: -16px;
        }

        .ucTableCellContent .ucStatus {
            margin-top: 0px;
        }

        .ant-table-thead {
            background: @table-header-color;

            .ucTable__column {
                height: auto;
                vertical-align: initial;
                .ucTable__cellContent {
                    position: relative;
                    top: initial;
                    left: initial;
                    height: auto;
                }
            }
            .ucTable__column .ucTable__cell--sortable {
                min-height: auto;
            }
        }

        thead {
            .ucTable__cellContentLabel {
                font-family: @font-heading;
                color: @pfx-color-grey-9!important;
            }

            // FIXME: in Unity
            .ucTableHeaderColumnContextMenu:hover,
            .ucTableHeaderColumnContextMenu--menuVisible {
                background: inherit !important;
            }
        }

        .ant-pagination-options-quick-jumper {
            width: auto !important;
        }

        .ucTableBody {
            & tr {
                transition-duration: 0s, 0s;
            }

            .ucTable__column--withButtons .ucTable__cell .ucTable__cellContent {
                padding: 3px 10px;
            }

            .pmTableActionButton {
                opacity: 0;
            }

            .ant-btn-link {
                font-size: 12px;
            }

            .ucTableRow:hover,
            .ucTableRow:focus-within {
                background-color: #fafafa !important;

                .pmTable__column--withActionButton
                    .ucTable__cell
                    .ucTable__cellContent {
                    padding-right: 34px;
                }

                .pmTableActionButton {
                    opacity: 1;
                }
            }

            .ucTableRow:focus-within {
                .pmTableActionButton .ucButton {
                    background-color: #e8e8e8;
                }
            }
        }

        &.pmTable--condensed {
            .ucTable
                .ant-table-tbody
                > tr
                td:first-child:not(.ant-table-selection-column).ucTable__column
                .ucTable__cellContent {
                padding-left: @pfx-spacing-16;
            }
            .ucTable
                .ant-table-tbody
                > tr
                td.ucTable__column
                .ucTable__cellContent {
                padding-left: @pfx-spacing-8;
                padding-right: @pfx-spacing-8;
            }
        }

        .ant-table-thead .ant-table-cell.ant-table-selection-column > div {
            width: auto;
        }

        .ucQuickFilter .ant-input {
            font-size: 13px;
        }
    }

    // Need table rowHeight={40}
    .pmTable--withInputs {
        .ucTableCellContentInner:has(input, .pmSwitchField) {
            padding: 1px;

            input,
            .ant-select-selection-item {
                font-size: 12px !important;
            }
        }
    }
    .pmTable--rowSelection tr {
        cursor: pointer;
    }

    .pmTable--nested {
        .ant-table-placeholder {
            position: relative;
        }
    }

    .pmTable--cellTop {
        .ant-table-cell {
            vertical-align: top;
        }
    }

    .pmTable--tallCells {
        .ant-table-tbody > tr td.ucTable__column .ucTable__cell {
            min-height: 51px;
        }

        .ant-table-tbody .ucTable__column .ucTable__cellContent > .ucButton {
            top: initial;
        }

        .pmTableActionButton .ucButton {
            top: 12px;
        }

        .ucTable__cellContent {
            display: flex;
            justify-items: center;
            align-items: center;

            > .ucButton {
                top: auto;
            }
        }
    }

    .pmTable-centeredCell {
        .ucTableCellContentInner {
            display: flex;
            align-items: center;
        }
    }

    .pmTable--fixCollapseWidth {
        & colgroup col:last-child {
            width: 100% !important;
            min-width: 100% !important;
        }
    }
    .pmTable--headerWithTwoRows {
        .ucTableGrid {
            height: auto !important;
        }

        .ucTableHeader,
        .ucTableHeaderCell,
        .ucTableHeaderCellContent__label {
            height: 42px;
        }
    }
}
