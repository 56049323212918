@import "../../../DesignSystem.style.less";

:global {
    .pmTableActionButton {
        .ucButton {
            position: absolute;
            top: 2px;
            right: 5px;
            display: inline-block;
            text-align: center;

            &:hover:not(.ucButton--disabled) {
                background-color: #f5f5f5;
                .anticon {
                    color: #8c8c8c !important;
                }
            }

            &:active {
                .anticon {
                    color: #8c8c8c;
                }
                background-color: #e8e8e8;
            }

            .anticon  {
                display: inline-flex;
                transform: rotate(90deg);
            }
        }
    }

    .pmTableActionButton-placeholder {
        width: 150px;
        .ucMenu__item:nth-child(1) {
            .ant-skeleton-paragraph {
                max-width: 60px;
            }
        }
        .ucMenu__item:nth-child(2) {
            .ant-skeleton-paragraph {
                max-width: 120px;
            }
        }
        .ucMenu__item:nth-child(3) {
            .ant-skeleton-paragraph {
                max-width: 80px;
            }
        }
        .ucMenu__item:nth-child(4) {
            .ant-skeleton-paragraph {
                max-width: 140px;
            }
        }

        .ant-skeleton {
            top: 6px;
            position: relative;
        }
    }
    .pmActionButtonItem--red:not(.ant-dropdown-menu-item-disabled) {
        color: @pfx-color-red-4;
    }
}
