@import "~antd/dist/antd.css";
@import "~@pricefx/unity-components/dist/unity-components.css";
@import "~font-awesome/css/font-awesome.min.css";

.header-go-to-pfx {
    min-width: 100px;
    margin-right: 20px;
    display: inline-block;
    font-size: 12px;
}

.ant-table-body .ant-form-item {
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.dagre-d3 .node > rect {
    stroke: #8b8d8f;
    stroke-width: 1px;
    border-radius: 4px;
    fill: #fafafa;
}

.dagre-d3 path {
    stroke: #8b8d8f;
    fill: #8b8d8f;
    stroke-width: 1.5px;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: inherit;
}

.ucForm .ant-legacy-form-item + .ucButton {
    margin-top: inherit;
}
