:global {
    .pmProgress {
        width: 100%;
    }

    .pmProgress-message {
        font-size: 12px;
        font-weight: 700;
    }
}
