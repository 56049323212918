@import "../../DesignSystem/DesignSystem.style.less";

:global {
    .pmGap {
    }

    .pmGap--small {
        padding-top: @pfx-spacing-8;
    }

    .pmGap--medium {
        padding-top: @pfx-spacing-16;
    }

    .pmGap--large {
        padding-top: @pfx-spacing-24;
    }

    .pmGap--x-large {
        padding-top: @pfx-spacing-24 + @pfx-spacing-8;
    }

    .pmGap--huge {
        padding-top: @pfx-spacing-24 + @pfx-spacing-16;
    }
}
