@import "../DesignSystem/DesignSystem.style.less";

:global {
    .pmDragger {
        & > span {
            display: block;
        }

        .ant-upload.ant-upload-drag {
            width: 100%;
            border-radius: 4px;
            height: 100%;
            border: 1px dashed #dfe1e6;
            background-color: rgba(255, 255, 255, 0.9);
            text-align: center;
            padding: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        .pmDragger-hint {
            font-size: 12px;
            line-height: 14px;
        }

        .pmDragger-title {
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: -0.006em;
            color: @pfx-color-grey-9;
        }

        .ant-upload.ant-upload-drag .ant-upload {
            padding: 0;
        }

        .ant-upload-list-item.ant-upload-list-item-list-type-text {
            height: 20px;

            .ant-upload-text-icon {
                display: none;
            }

            .ant-upload-list-item-name {
                padding: 0;
                font-size: 13px;
            }

            .ant-upload-list-item-card-actions-btn {
                opacity: 1;
            }

            .ant-upload-list-item-info {
                background: transparent;
                padding: 4px 0;

                svg {
                    fill: #6e7178;
                    width: 18px;

                    &:hover {
                        fill: #1c8db0;
                    }
                }
            }
        }
    }
}
