:global {
    .pmSwitch {
    }

    .ucForms
        .ant-form-item
        > .ant-form-item-control
        > .ant-form-item-control-input
        > .ant-form-item-control-input-content
        .pmSwitch {
        width: auto;
    }
}
