@import "../DesignSystem/DesignSystem.style.less";

:global {
    .pmUploadWithIcon {
        width: 360px;
    }
}

.uploadProgressBox {
    margin-top: 30px;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.43);
}

.textAlignRight {
    text-align: right;
}

.uploadHint {
    margin-top: 12px !important;
    color: grey;
    font-size: 13px;
}

.uploadButton {
    margin-top: 3px;
}
