@import "../DesignSystem.style.less";

:global {
    .pmContainer {
        &--padding {
            &-lg {
                padding: @pfx-spacing-lg;
            }

            &-md {
                padding: @pfx-spacing-md;
            }

            &-sm {
                padding: @pfx-spacing-sm;
            }

            &-xs {
                padding: @pfx-spacing-xs;
            }
        }

        &--margin {
            &-lg {
                margin: @pfx-spacing-lg;
            }

            &-md {
                margin: @pfx-spacing-md;
            }

            &-sm {
                margin: @pfx-spacing-sm;
            }

            &-xs {
                margin: @pfx-spacing-xs;
            }
        }
        &--scrollable {
            height: 100%;
            overflow: auto;
        }
    }
}
