@import "../../DesignSystem.style.less";

:global {
    .ucForms {
        .pmSwitchField {
            &.pmSwitchField--narrow {
                .pmSwitch {
                    max-width: 20px !important;
                }
            }
            &.pmSwitchField--wider {
                .pmSwitch {
                    width: auto !important;
                }
            }
            &.pmSwitchField--vertical {
                .ant-form-item.ucFormsField {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .ant-form-item-label {
                        order: 2;
                    }

                    .ucFormsField__label {
                        letter-spacing: -0.006em;
                        font-size: 14px;
                        font-weight: 400;
                        color: @pfx-color-grey-8;
                        padding-left: 4px;
                    }

                    .ant-form-item-control {
                        order: 1;
                        padding-top: 0;
                        max-width: 50px;
                    }
                }
            }
        }
    }
}
