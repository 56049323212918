@import "../../DesignSystem/DesignSystem.style.less";

:global {
    .pmCard {
        & > div {
            padding: 0;
        }

        .ant-card-head-wrapper {
            font-size: 13px;
        }

        &--raised {
            box-sizing: border-box;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
            border-radius: 4px;
        }

        &--small {
            & > div {
                &:not(.ant-card-head) {
                    padding: @pfx-spacing-8;
                }
                padding-left: @pfx-spacing-8;
            }
        }
        &--medium {
            & > div {
                &:not(.ant-card-head) {
                    padding: @pfx-spacing-16;
                }
                padding-left: @pfx-spacing-16;
            }
        }
        &--large {
            & > div {
                &:not(.ant-card-head) {
                    padding: @pfx-spacing-24;
                }
                padding-left: @pfx-spacing-24;
            }
        }
        &--x-large {
            & > div {
                &:not(.ant-card-head) {
                    padding: @pfx-spacing-24 + @pfx-spacing-8;
                }
                padding-left: @pfx-spacing-24 + @pfx-spacing-8;
            }
        }
        &--huge {
            & > div {
                &:not(.ant-card-head) {
                    padding: @pfx-spacing-24 + @pfx-spacing-16;
                }
                padding-left: @pfx-spacing-24 + @pfx-spacing-16;
            }
        }

        &--footer {
            border-top: 1px solid @border-dividers;
            padding: 0;
        }

        &--background-white {
            background-color: @pfx-color-white !important;
        }

        &--background-grey {
            background-color: @pfx-color-grey-2 !important;
        }
    }
}
