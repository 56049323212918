/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@font-face {
  font-family: 'Custom Inter';
  src: url('../../../../node_modules/@pricefx/unity-components/src/fonts/inter/Inter-Regular.woff2') format('woff2'), url('../../../../node_modules/@pricefx/unity-components/src/fonts/inter/Inter-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Custom Inter';
  src: url('../../../../node_modules/@pricefx/unity-components/src/fonts/inter/Inter-Italic.woff2') format('woff2'), url('../../../../node_modules/@pricefx/unity-components/src/fonts/inter/Inter-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Custom Inter';
  src: url('../../../../node_modules/@pricefx/unity-components/src/fonts/inter/Inter-Medium.woff2') format('woff2'), url('../../../../node_modules/@pricefx/unity-components/src/fonts/inter/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Custom Inter';
  src: url('../../../../node_modules/@pricefx/unity-components/src/fonts/inter/Inter-MediumItalic.woff2') format('woff2'), url('../../../../node_modules/@pricefx/unity-components/src/fonts/inter/Inter-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Custom Inter';
  src: url('../../../../node_modules/@pricefx/unity-components/src/fonts/inter/Inter-SemiBold.woff2') format('woff2'), url('../../../../node_modules/@pricefx/unity-components/src/fonts/inter/Inter-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Custom Inter';
  src: url('../../../../node_modules/@pricefx/unity-components/src/fonts/inter/Inter-SemiBoldItalic.woff2') format('woff2'), url('../../../../node_modules/@pricefx/unity-components/src/fonts/inter/Inter-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}
:global .ucHeadingH1 {
  font-size: 30px;
  line-height: 54px;
}
:global .ucHeadingH2 {
  font-size: 19px;
  line-height: 40px;
}
:global .ucHeadingH3 {
  font-size: 17px;
  line-height: 34px;
}
:global .ucHeadingH4 {
  font-size: 15px;
  line-height: 28px;
}
:global .ucHeadingH5 {
  font-size: 13px;
  line-height: 24px;
}
