@import "../../../components/DesignSystem/DesignSystem.style.less";

:global {
    .pmCodeMirror {
        width: 100%;
        border: 1px solid @pfx-color-grey-5;
        border-radius: 4px;
        overflow: hidden;
    }
}
