@import "../../../DesignSystem/DesignSystem.style.less";

:global {
    .pmText {
        font-size: @text-14;
        color: @text-color;
        margin: 0;

        &--small {
            font-size: @text-12;
            line-height: @line-height-6;
        }
        &--medium {
            font-size: @text-14;
            line-height: @line-height-5;
        }
        &--large {
            font-size: @text-15;
            line-height: @line-height-4;
        }

        &--text-color-error {
            color: @error-color;
        }

        &--text-color-success {
            color: @pfx-color-green-4;
        }

        &--text-color-warning {
            color: @pfx-color-yellow-4;
        }

        &--text-color-primary {
            color: @color-primary-text;
        }

        &--text-color-secondary {
            color: @color-secondary-text;
        }

        &--text-color-title {
            color: @color-title;
        }

        &--text-color-current {
            color: currentColor;
        }
    }
}
