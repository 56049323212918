@import "../../DesignSystem/DesignSystem.style.less";

:global {
    .pmAlert {
        &:not(.ant-alert-with-description) {
            .ant-alert-message {
                font-size: @text-14;
                color: @text-color;
            }
        }

        .ant-alert-message {
            font-size: @text-16;
            color: @heading-color;
        }

        .ant-alert-description {
            font-size: @text-14;
            color: @text-color;
        }
        .ant-alert-close-icon {
            font-size: @text-16;
            line-height: @text-12;
        }

        &:not(.big) {
            .ant-alert-icon {
                font-size: @text-16;
            }
        }
        .anticon {
            &.anticon-info-circle {
                color: @info-color;
            }
            &.anticon-check-circle {
                color: @success-color;
            }
            &.anticon-exclamation-circle {
                color: @warning-color;
            }
            &.anticon-close-circle {
                color: @error-color;
            }
        }
    }
}
