:global {
    .pmSearchSize {
        // TODO: Fix in Unity https://pricefx.atlassian.net/browse/PFUN-12285
        .ant-input-affix-wrapper {
            padding: 4px 11px;
        }
    }
    .pmSearchSizeStandard {
        max-width: 340px;
    }
}
