:global {
    .pm-external-link-hover-icon {
        > .pmIcon {
            visibility: hidden;
        }
        &:hover > .pmIcon {
            visibility: visible;
        }
    }
}
