:global {
    .pmDeleteRowButton {
        opacity: 0;
    }

    .ant-table-row .pmDeleteRowButton {
        margin-left: 8px;
    }

    .pmDeleteRow:hover .pmDeleteRowButton,
    .ant-table-row:hover .pmDeleteRowButton,
    .ucTableRow:hover .pmDeleteRowButton,
    .ucFormsFieldGrid__row:hover .pmDeleteRowButton {
        opacity: 1;
    }
}
