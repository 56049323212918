@import "../../DesignSystem/DesignSystem.style.less";

:global {
    .ant-checkbox-wrapper.ant-checkbox-group-item {
        &::after {
            display: none;
        }
    }
    .check-group-button {
        .ant-checkbox {
            opacity: 0;

            &-wrapper {
                text-align: center;
                position: relative;
                display: inline-block;
                height: 32px !important;
                margin: 0;
                padding: 0 14px !important;
                color: @pfx-color-grey-8;
                line-height: 30px;
                background: @pfx-color-white;
                border: 1px solid @pfx-color-grey-5;
                border-top-width: 1.02px;
                border-left: 0 !important;
                cursor: pointer;
                -webkit-transition: color 0.3s, background 0.3s,
                    border-color 0.3s;
                transition: color 0.3s, background 0.3s, border-color 0.3s;

                &-checked {
                    color: @pfx-color-white;
                    background: @pfx-color-primary;
                    border-color: @pfx-color-primary;

                    &:hover {
                        color: @pfx-color-white !important;
                        background: @pfx-color-hover;
                        border-color: @pfx-color-hover;
                    }

                    &:not(:last-child) {
                        border-right: 1px solid @pfx-color-grey-5;
                    }
                }

                &:not(-checked):hover {
                    color: @pfx-color-primary;
                }

                &:first-child {
                    border-left: 1px solid @pfx-color-grey-5 !important;
                    border-radius: 4px 0 0 4px;
                }

                &:last-child {
                    border-radius: 0 4px 4px 0;
                }
            }
        }

        span {
            padding: 0 !important;

            &.ant-checkbox-inner {
                width: 0;
                height: 0;
                opacity: 0;
            }
        }

        .ant-checkbox-checked {
            span {
                &.ant-checkbox-inner {
                    width: 0;
                    height: 0;
                    opacity: 0;
                }
            }
        }
    }
}
