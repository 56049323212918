@import "../DesignSystem.style.less";

:global {
    .pmInput {
        // TODO: fix in unity - reflected from
        // @see https://www.figma.com/file/oFsKK94Y8HwZ3TdWSUe4AZ/Margin-1.0---(Unity-%26-Blade)?node-id=0%3A1
        &.ant-input:not(.ant-input-sm),
        &.ant-input-affix-wrapper {
            height: 32px;
            border-radius: 4px;
        }
    }

    .ant-input-group-addon,
    .ant-input-number-group-addon {
        .ucFormsField {
            width: auto !important;
            min-width: auto !important;
            height: auto !important;
            margin: 0 !important;
        }

        .ant-form-item-control-input {
            min-height: 30px !important;
        }

        .ant-col {
            padding-top: 0 !important;
        }
    }
}
