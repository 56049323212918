.container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 24em;
}

.spinner {
    flex: 1;
}
