@import "../DesignSystem.style.less";

:global {
    .pmMenu {
        .ant-menu-submenu-selected .ant-menu-submenu-title {
            color: @pfx-color-blue-4!important;
        }

        .ant-menu-item a,
        &.ucMenu--mode-inline.ant-menu-inline
            .ant-menu-submenu
            > .ant-menu-submenu-title {
            color: @pfx-color-grey-9;
        }

        &.ucMenu--mode-inline.ant-menu-inline > .ucMenu__item {
            padding-left: 24px !important;
            font-weight: 500;
            color: @pfx-color-grey-9;
        }

        &.ucMenu--mode-inline.ant-menu-inline .ant-menu-sub > .ucMenu__item,
        &.ucMenu--mode-inline.ant-menu-inline
            .ant-menu-sub
            .ant-menu-title-content
            .ucMenu__item {
            padding-left: 36px !important;
        }

        &.ucMenu--mode-inline.ant-menu-inline
            .ant-menu-submenu
            > .ant-menu-submenu-title
            .ant-menu-submenu-expand-icon {
            left: initial;
        }

        .ant-menu-item {
            font-size: @text-12;
            transform: none;
            transition: none;
            border-right: none;
            padding: 0 22p;
        }

        &.ucMenu--mode-inline.ant-menu-inline
            .ant-menu-submenu
            > .ant-menu-submenu-title:focus {
            border: 0;
            background: none;
        }

        .ant-menu-item-group-title {
            height: auto;
        }
    }
}
